export const addItem = (product) => {
    return {
      type : "ADDITEM",
      payload : product  
    }
} 
export const delItem = (product) => {
    return {
        type : "DelITEM",
        payload : product
    }
}